import React from 'react';
import Button from './Assets/Button';



export default function About() {

    return (
        <div className='about flex flex-col h-full items-center py-20'>
                <div className='text-center text-slate-300 text-xl w-80 js-show-on-scroll'>Iron Web is here to take the complexity out of web development. Our team designs and develops modern, tailored solutions that bring your website into a new era - working fluidly on any device or platform for maximum performance at scale with your business's growth.</div>
            <div className='mt-10 w-full offers flex justify-around h-[350px]'>
            <div className='about-sections flex flex-row items-center overflow-scroll scrollbar-hide'>
            <div className='text-white font-light h-[175px] rounded-xl p-5 business-sites text-center text-transparent text-xl'>
                    <div className='relative group abt'>

                        <div className='absolute -inset-0.5 bg-gradient-to-r from-blue-900 to-indigo-700 rounded-lg blur opacity-75 transition duration-1000 animate-tilt'></div>
                        <div className='a bg-gray-900 rounded-lg relative px-4 py-2 h-[175px]'>
                            <div className='mb-5 font-normal'>
                                Portfolios
                            </div>
                            <div className='info text-lg'>
                                Have work you want to showcase? Looking to land your next gig? Allow us to design your portfolio.
                            </div>
                        
                        </div>
                            
                    </div>
                </div>
                <div className='text-white font-light h-[175px] rounded-xl p-5 business-sites text-center text-transparent text-xl'>
                    <div className='relative group abt'>

                        <div className='absolute -inset-0.5 bg-gradient-to-r from-blue-900 to-indigo-700 rounded-lg blur opacity-75 transition duration-1000 animate-tilt'></div>
                        <div className='a bg-gray-900 rounded-lg relative px-4 py-2 h-[175px]'>
                            <div className='mb-5 font-normal'>
                                Landing Pages
                            </div>
                            <div className='info text-lg'>
                                Working on the next big thing? You need a landing page to gather leads and showcase what you can provide with a landing page. 
                            </div>
                        
                        </div>
                            
                    </div>
                </div>
                
                <div className='text-white font-light h-[175px] rounded-xl p-5 business-sites text-center text-transparent text-xl'>
                    <div className='relative group abt'>

                        <div className='absolute -inset-0.5 bg-gradient-to-r from-blue-900 to-indigo-700 rounded-lg blur opacity-75 transition duration-1000 animate-tilt'></div>
                        <div className='a bg-gray-900 rounded-lg relative px-4 py-2'>
                            <div className='mb-5 font-normal'>
                                Business Websites
                            </div>
                            <div className='info text-lg'>
                                Businesses need eye-catching websites to showcase their products and services. Allow us to help you express what you can provide customers.
                            </div>
                        
                        </div>
                            
                    </div>
                </div>
            </div>
        </div>
                <Button color='blue'/>
    </div>
    )
}

