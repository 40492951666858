import './App.css';
import {Routes, Route} from 'react-router-dom';

import NavBar from './Components/NavBar'; 
import Footer from "./Components/Footer";
import Portfolio from "./Components/Portfolio";

import MainSection from "./Components/MainSection";
import Careers from './Components/Careers';
import { useEffect } from 'react';


function App() {


  useEffect(() => {
        // Get all the elements you want to show on scroll
    const targets = document.querySelectorAll(".js-show-on-scroll");
    const callback = function(entries) {
      entries.forEach(entry => {

        // Is the element in the viewport?
        if (entry.isIntersecting) {
          console.log('in')
          // Add the fadeIn class:
          entry.target.classList.add("motion-safe:animate-fadeIn");
        } else {
          console.log('out')
          // Otherwise remove the fadein class
          entry.target.classList.remove("motion-safe:animate-fadeIn");
        }
      });
    };
    // Set up a new observer
    const observer = new IntersectionObserver(callback);

    // Loop through each of the target
    targets.forEach(function(target) {
      // Hide the element
      // target.classList.add("opacity-0");

      // Add the element to the watcher
      observer.observe(target);
    });

    // Callback for IntersectionObserver
   
  }, [])

  return (

     <div className='app font-basich-full bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400'>
        <NavBar />
        <Routes>
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/' element={<MainSection />} />
          <Route path='/careers' element={<Careers />} />
        </Routes>
        <Footer />

     </div>
    
  );
}



export default App;
