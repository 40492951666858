import React from 'react';


export default function Careers() {


    return (
        <div className='hero h-screen pt-72 px-8 flex flex-col items-center text-center bg-clip-text text-transparent   bg-gradient-to-b from-white to-zinc-400'>
            <div className='text-zinc-200 text-5xl mb-9'>Careers</div>
            <div className='text-slate-400 text-2xl'>We currently have no careers available at this time. Keep your eyes peeled for future openings!</div>
        </div>
    )
}