import React, {useState} from 'react';


export default function Faq() {

    function handleClick(id) {
        setCurrentQuestion(id);
    }
    const questions = [
        {
            question: 'What is SEO?',
            answer: 'Search Engine Optimization involves making semantic changes to your website in order to make it more relevant to your niche, and to appear higher in things like Google searches. This is pivotal for finding leads!',

        }, 
        {
            question: 'What is your upfront payment cost?',
            answer: 'We ask for 30% of total cost up front, and the rest upon delivery.',
            
        },
        {
            question: 'Do you cover hosting fees and domain name purchases?',
            answer: 'No, we do not cover hosting fees or domain name purchases (don\'t worry, we\'ll still configure it for you!)',
            
        },
        {
            question: 'Do you use responsive design for your websites?',
            answer: 'Yes, we design and develop responsive websites that are optimized for all devices and screen sizes.',
            
        },
        {
            question: 'How long does it take to develop a website?',
            answer: 'Landing pages and portfolios take anywhere from 5-7 days for delivery. Websites outside of that scope will vary depending on the proposed and agreed upon timeline.',
            
        },
        {
            question: 'How much does a website cost?',
            answer: `See our Pricing Section below for more information!`,
            
        }
    ]
    const [currentQuestion, setCurrentQuestion] = useState(0);
    
    return (
        <div className='relative mx-3 px-8 mb-24 flex flex-col items-center justify-center'>
            <div className='relative mb-10 text-center text-3xl bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400'>Frequently Asked Questions</div>
            <div className='faq-container flex flex-col items-center text-white text-md gap-y-8 sm:w-1/2'>
                {questions.map((q, idx) => {
                    const classNames= currentQuestion === idx ? 'relative transition duration-150' : 'relative  text-transparent transition duration-150';
                    return (
                        <div key={idx} onClick={(() => handleClick(idx))} className= { currentQuestion  === idx ? 'relative max-h-[200px] transition duration-500 relative w-full px-6 py-4 rounded-xl hover:cursor-pointer' : 'relative max-h-[50px] transition duration-500 relative  w-full px-6 py-4 rounded-xl hover:cursor-pointer'}>
                            <div className='absolute -inset-0.5 bg-gradient-to-r from-pink-500 to-indigo-700 rounded-lg blur opacity-75 transition duration-1000 animate-tilt'></div>
                            <div className='absolute -inset-0.5 rounded-xl bg-gray-900 bg-opacity-70'></div>
                            <div className='relative text-xl mb-4'  >{q.question}</div>
                            <div className={classNames}>{q.answer}</div>
                        </div>
                    )
                 })}
            </div>
        </div>
      
        )
}