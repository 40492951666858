import React from 'react';
import PricingCard from './PricingCard';
export default function Pricing() {
    const prices = [
        {name: 'Single Page Website', description: 'A persuasive, long website with a lead capture form', price: '$250 USD'},
        {name: 'Multi-Page Website', description: '2-10 additional pages, with lead capture', price: '$300-850 USD'},
        {name: 'E-Commerce Website', description: 'Unlimited pages, flexible payment integration, and simple CMS to manage products!', price: '$1200 USD'},
        {name: 'Need Something else?', description: 'Not sure what you want? Have something ambitious in mind? Reach out to us and we can find the web solution for you!', price: 'Inquiry'}
    ]
    return (
        <div className='text-white flex flex-col px-8 mb-48 items-center'>
            <div className='relative mb-10 text-center text-3xl bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400'>
                Pricing
            </div>
            <div className='sm:w-1/2 pricing-list flex flex-col gap-y-10'>
                {
                    prices.map((item, idx) => {
                        return (
                        <PricingCard key={idx} name={item.name} description={item.description} price={item.price}/>
                        )
                    })
                }
            </div>
        </div>
    )
}