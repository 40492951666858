import React, { useState } from 'react';
import axios from 'axios';

export default function Contact() {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [showSubmission, setShowSubmission] = useState(false);

    const url = process.env.PUBLIC_API_URL || 'https://iron-web-site.onrender.com/contact';

    function handleSubmit(e) {
        e.preventDefault();
        // let xhr = new XMLHttpRequest();
        // xhr.open('POST', '/');
        // xhr.setRequestHeader('content-type', 'application/json');
        const formData = {
            email: email,
            subject: subject,
            message: message
        }
        axios.post(url, formData)
            .then(res => {
                // console.log(`Response: ${res.body}`)
                setEmail('');
                setSubject('');
                setMessage('');
                setShowSubmission(true);
                setTimeout(() => {
                    setShowSubmission(false)
                }, 5000)
            })
            .catch((error) => {
                // console.log(error.data)
            }) 

    }

    const showSubClasses = 'transition-all duration-500 w-full h-full flex flex-col justify-center bg-black bg-opacity-90 absolute '
    return (
        <div id='contact' className='relative px-5 contact h-screen flex flex-col items-center'> 
            <section className="relative bg-gray-900 rounded-xl sm:w-1/2">
                <div className={showSubmission ? showSubClasses : showSubClasses + 'hide'}>
                    <div className='mb-4 text-4xl tracking-tight font-extrabold text-center bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400'>Submission Received!</div> 
                    <div className='text-xl tracking-tight font-extrabold text-center bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400'>We'll get back to you soon, thank you!</div>
                </div>
                <div className="flex flex-col items-center py-8 lg:py-16 px-4 mx-auto ">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400">Contact Us</h2>
                    <p className="lg:w-4/5 lg:text-center mb-8 lg:mb-16 font-light text-center text-gray-400 sm:text-xl">Ready to start? Let's get in touch!</p>
                    <form onSubmit={handleSubmit} className="space-y-8 flex flex-col items-center">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-300">Your email</label>
                            <input value={email} onChange={e => setEmail(e.target.value)} type="email" id="email" className="w-72 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light" placeholder="name@ironweb.dev" required />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-300">Subject</label>
                            <input value={subject} onChange={e => setSubject(e.target.value)} type="text" id="subject" className="w-72 block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light" placeholder="Let us know how we can help you" required />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-400">Your message</label>
                            <textarea value={message} onChange={e => setMessage(e.target.value)} id="message" rows="6" className="w-72 block p-2.5 text-sm rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                        </div>
                        <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:ring-4 focus:outline-none bg-gradient-to-t from-blue-800 to-blue-600 hover:bg-primary-700 focus:ring-primary-800">Send message</button>
                    </form>
                </div>
                <div className='flex flex-col items-center mb-8 gap-y-4 text-xl text-white'>Or Schedule a Consulation Call! <a className='text-center text-lg px-4 py-2 rounded-xl bg-gradient-to-b from-indigo-800 to-blue-600 w-[100px] text-base font-semibold' target='_blank' rel='noreferrer' href='https://calendly.com/iron-web/website-consultation'>Click here!</a></div>
            </section>
        </div>                    
            
    )
}