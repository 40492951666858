import React from 'react';
import ReactPlayer from 'react-player';


export default function PortfolioCard(props) {
    
    return (
        <a href='https://dtren800.com' target='_blank' rel='noreferrer' className='relative flex flex-col items-center'>
            <div className='relative portfolio-card w-4/6 lg:w-1/3 space-y-1 flex flex-col items-center'>
                <ReactPlayer loop={true} muted={true} className='player-wrapper' playing={true} url= 'videos/dtrenvid.mp4' width='100%' height='100%' controls= {false} />
                {/* <img alt='portfolio tag' className='h-72 w-auto mb-4' src={props.imgSrc} /> */}
                
            </div>
        </a>
    )
}