import React from 'react';


export default function Button(props) {

    return (
        <a href='#contact' className='w-full items-center flex justify-center relative'>

            <button type="button" className={`py-2 px-4  bg-gradient-to-b from-${props.color === 'blue' ? 'blue-800' : 'yellow-300'} to-${props.color === 'blue' ? 'blue-600' : 'yellow-600'} hover:bg-blue-700  text-white w-[100px] lg:w-[150px] transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-full `}>
                Contact us
            </button>
        </a>
    )
}