import React from 'react';
import Button from './Assets/Button';

export default function PricingCard(props) {

    return (
        <div className='relative flex flex-col gap-y-8 rounded-xl cardtext-center bg-slate-700'>
            <div className='absolute -inset-0.5 bg-gradient-to-r from-blue-900 to-indigo-700 rounded-lg blur opacity-100 transition duration-1000 animate-tilt'></div>
            <div className='flex flex-col justify-center items-center gap-y-6 bg-gray-900 relative  px-5 py-8 rounded-xl '>

                <div className='text-2xl font-nav font-bold relative'>{props.name}</div>
                <div className='relative bg-clip-text text-transparent text-4xl bg-gradient-to-b from-white to-zinc-400 text-4xl font-bold'>{props.price}</div>
                <div className='text-center relative'>{props.description}</div>
                <Button color='blue'/>
            </div>
        </div>
    )
}