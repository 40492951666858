import React from 'react';

import Hero from "./Hero";
import About from "./About";
import Contact from "./Contact";
import Faq from './Faq';
import Pricing from './Pricing';
export default function MainSection() {

    return (
        <div className=''>
            <Hero />
            <About />
            <Faq />
            <Pricing />
            <Contact />
        </div>
    )
}