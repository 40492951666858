import React from 'react';
import PortfolioCard from './PortfolioCard';
import dtren from '../images/dtren.png';

export default function Portfolio() {

    const portfolios = [
        {
            img: dtren,
            title: 'Dtren800\'s Website',
            description: 'A lightweight, fast custom website for fitness influencer Dawson Weiss (Dtren800), made with custom styling and animations.'

        }
    ]

    return (
        <div className='portfolio h-screen flex items-center flex-col pb-20'>
            <div className='text-2xl font-light-normal text-center mt-10 mb-20 bg-clip-text text-transparent bg-gradient-to-b from-white to-zinc-400'>Take a look at some of our past, satisfied clients</div>
            <div className='portfolios-wrapper flex flex-row justify-center'>
                {portfolios.map((item, i) => {
                    return (
                        <PortfolioCard link='https://dtren800.com' key={i} imgSrc={item.img} title={item.title} description={item.description}/>
                    )
                })}
            </div>
        </div>
    )
}