import React from 'react';



export default function Hero() {

    return (
        <div className=' hero text-white flex flex-col items-center h-screen justify-center motion-safe:animate-blurIn'>
            <div className='titles justify-center flex flex-col items-center mb-20 space-y-7'>
                <div className='bg-clip-text text-transparent text-5xl md:text-8xl bg-gradient-to-b from-white to-zinc-400'>WE BUILD IT</div>
                <div className='text-center bg-clip-text text-transparent text-2xl bg-gradient-to-b from-white to-zinc-400'>Engineers delivering high quality websites</div>

                <a href='#contact' className='w-full items-center flex justify-center'>

                    <button type="button" className="py-2 px-4  bg-gradient-to-t from-blue-800 to-blue-600 hover:bg-blue-700  text-white w-[100px] lg:w-[150px] transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-full ">
                        Contact us
                    </button>
                </a>

            </div>
        </div>
    )
}